import * as React from "react";
import Layout from "../../layout";

const FaqPage = () => {
  return (
    <Layout
      title={"Welcome to CORSnet"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in SriLanka!"
      }
    >
      <section className="wrapper bg-light angled upper-end">
        <div className="container pb-14 pb-md-16">
          <section className="row mb-8">
            <div className="col-12 mt-n20"></div>
          </section>
        </div>
      </section>
    </Layout>
  );
};

export default FaqPage;
